<template>
<div>
  <!--begin::Dashboard-->
  <h2 class="font-poppins">Today's Orders Statistics</h2>
  <div class="row">
    <div class="col-xl-4">
      <TotalOrdersWidget :orders="form.orders"></TotalOrdersWidget>
    </div>
    <div class="col-xl-4">
      <TodayOrdersCountWidget :orders="form.orders"></TodayOrdersCountWidget>
    </div>
    <div class="col-xl-4">
      <TodayOrdersCountWidget :orders="form.orders"></TodayOrdersCountWidget>
    </div>
      <!-- 
    <div class="col-xxl-4">
      <ListWidget9></ListWidget9>
    </div>
    <div class="col-xxl-4">
      <StatsWidget7></StatsWidget7>
      <StatsWidget12></StatsWidget12>
    </div>

    <div class="col-xxl-4 order-1 order-xxl-1">
      <ListWidget1></ListWidget1>
    </div>
    <div class="col-xxl-8 order-2 order-xxl-1">
      <AdvancedTableWidget2></AdvancedTableWidget2>
    </div>

    <div class="col-xxl-4 order-1 order-xxl-2">
      <ListWidget3></ListWidget3>
    </div>
    <div class="col-xxl-4 order-1 order-xxl-2">
      <ListWidget4></ListWidget4>
    </div>
    <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
      <ListWidget8></ListWidget8>
    </div>

    <div class="col-xxl-4 order-1 order-xxl-2">
      <StatsWidget13></StatsWidget13>
    </div>
    <div class="col-xxl-8 order-1 order-xxl-2">
      <AdvancedTableWidget3></AdvancedTableWidget3>
    </div> -->
  </div>
  <div class="row">
    <div class="col-xl-12" v-if="form.orders.length>0">
      <RecentOrdersWidget :orders="form.orders"></RecentOrdersWidget>
    </div>
    <div class="col-xl-12" v-else >
      <center><h2 class="font-poppins">No Orders for Today</h2></center>

    </div>
  </div>
  <!--end::Dashboard-->
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import TodayOrdersCountWidget from "@/view/pages/orders/components/TodayOrdersCountWidget.vue";
import RecentOrdersWidget from "@/view/pages/orders/components/RecentOrdersWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
// import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
// import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
name: "dashboard",
components: {
  TotalOrdersWidget,
  TodayOrdersCountWidget,
  RecentOrdersWidget,
},
data() {
  return {
    form: {
      orders: "",
    },
  };
},
mounted() {
  this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  var d = new Date();
  this.fetchOrders({
    start : d.getFullYear()+"-"+((d.getMonth()+1) < 10 ? "0"+(d.getMonth()+1) : d.getMonth()+1)+"-"+(d.getDate() < 10 ? "0"+d.getDate() : d.getDate()),
    end : d.getFullYear()+"-"+((d.getMonth()+1) < 10 ? "0"+(d.getMonth()+1) : d.getMonth()+1)+"-"+(d.getDate() < 10 ? "0"+d.getDate() : d.getDate()),
  });
},
methods: {
  setActiveTab1(event) {
    this.tabIndex = this.setActiveTab(event);
  },
  setActiveTab2(event) {
    this.tabIndex2 = this.setActiveTab(event);
  },
  /**
   * Set current active on click
   * @param event
   */
  setActiveTab(event) {
    // get all tab links
    const tab = event.target.closest('[role="tablist"]');
    const links = tab.querySelectorAll(".nav-link");
    // remove active tab links
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }

    // set current active tab
    event.target.classList.add("active");

    // set clicked tab index to bootstrap tab
    return parseInt(event.target.getAttribute("data-tab"));
  },

  fetchOrders(obj) {
    this.$store
      .dispatch(ORDERS , obj)
      // go to which page after successfully login
      .then((resp) => {
        this.form.orders = resp.data;
        // this.$router.push({ name: "dashboard" })
      })
      .catch((error) => {
        console.log(error);
      });
  },

}
};
</script>
